import type { IntlKey } from '@/locales/intlKey';

export const en: Record<IntlKey, string> = {
  '{error}': '{error}',
  'alert.selectPriceToExecute': 'Please select the price you want to execute',
  'alert.confirmDealerExecution': 'Please indicate if the dealer has confirmed or missed the trade execution.',
  'Access denied': 'Access denied',
  'acknowledge.confirmation': 'Would you like to proceed and confirm the dealer has executed the trade?',
  Advised: 'Advised',
  'Advised?': 'Advised?',
  'Advised strategy?': 'Advised strategy?',
  'An unexpected error has occurred.': 'An unexpected error has occurred.',
  'An unexpected error occurred,': 'An unexpected error occurred,',
  'An unexpected error occurred. Please try again or contact us if the issue persists.':
    'An unexpected error occurred. Please try again or contact us if the issue persists.',
  'Auction cancellation failed': 'Auction cancellation failed',
  'Auction cancelled': 'Auction cancelled',
  'Auction created': 'Auction created',
  'Auction updated': 'Auction updated',
  'Auction end time must be after auction start time': 'Auction end time must be after auction start time',
  'Auction end date': 'Auction end date',
  'Auction end time': 'Auction end time',
  'Auction start date': 'Auction start date',
  'Auction start time': 'Auction start time',
  'Auction {strategy} has been updated': 'Auction {strategy} has been updated',
  'sgWay.SgBuy': 'SG buys',
  'sgWay.SgSell': 'SG sells',
  'negotiationType.LISTED': 'Listed',
  'negotiationType.OTC': 'OTC',
  'deliveryType.CASH': 'Cash',
  'deliveryType.PHYSICAL': 'Physical',
  'optionStyle.EUROPEAN': 'European',
  'optionStyle.AMERICAN': 'American',
  Back: 'Back',
  Bidding: 'Bidding',
  Cancel: 'Cancel',
  'Cancel auction': 'Cancel auction',
  Confirm: 'Confirm',
  'Confirm derogation': 'Confirm derogation',
  'Confirm execution': 'Confirm execution',
  'Contact us': 'Contact us',
  'Copy auction ID': 'Copy auction ID',
  'Copy RFQ ID': 'Copy RFQ ID',
  'copyToClipboard.error': 'Text could not copied to clipboard, you may try with another browser',
  'copyToClipboard.success': 'Text copied to your clipboard',
  'Create auction': 'Create auction',
  'Dark theme': 'Dark theme',
  dealer: 'dealer',
  dealers: 'dealers',
  Dealers: 'Dealers',
  'Dealers list': 'Dealers list',
  'Dealer status updated to "confirmed".': 'Dealer status updated to "confirmed".',
  'Dealer status updated to "missed".': 'Dealer status updated to "missed".',
  'selected for bidding.': 'selected for bidding.',
  'Delivery style': 'Delivery style',
  'Delivery type': 'Delivery type',
  Discard: 'Discard',
  Dismiss: 'Dismiss',
  'deliveryStyle.CASH': 'Cash',
  'deliveryStyle.PHYSICAL': 'Physical',
  'PDC derogation failed': 'PDC derogation failed',
  'Determination method': 'Determination method',
  'determinationMethod.CLOSING_PRICE': 'Closing price',
  'determinationMethod.EDSP': 'EDSP',
  'determinationMethod.EXCHANGE_LOOK_ALIKE': 'Exchange Look Alike',
  'determinationMethod.HEDGE_EXECUTION': 'Hedge execution',
  'determinationMethod.MORNING_CLOSE': 'Morning close',
  'determinationMethod.NONE': 'None',
  'determinationMethod.OPENING_PRICE': 'Opening price',
  'determinationMethod.OTHER': 'Other',
  'determinationMethod.PER_MASTER_CONFIRM': 'Per master confirm',
  'determinationMethod.VWA_PPRICE': 'VWA price',
  'Edit auction details': 'Edit auction details',
  'Edit details': 'Edit details',
  Enter: 'Enter',
  'Error(s) occurred': 'Error(s) occurred',
  'Executed quantity': 'Executed quantity',
  'Execution done': 'Execution done',
  'Execution failed': 'Execution failed',
  'executionDetails.title': 'Execution summary',
  'executionDetails.dealer': 'Dealer',
  'executionDetails.instrument': 'Instrument',
  'executionDetails.quantity': 'Executed quantity',
  'executionDetails.price': 'Executed price',
  'executionDetails.bookingPortfolio': 'Booking portfolio',
  'executionDetails.bookingCentre': 'Booking centre',
  'executionDetails.markitWireId': 'MarkitWire ID',
  'executionDetails.status': 'Status',
  'executionDetails.status.acknowledged': 'Confirmed',
  'executionDetails.status.missed': 'Missed',
  'executionDetails.status.pending': 'Pending',
  'executionStatus.confirmed': 'Confirmed',
  'executionStatus.pending': 'Pending',
  'executionPendingBanner.missed': 'Missed',
  'executionPendingBanner.confirmed': 'Confirmed',
  enrichmentFailedToastTitle: 'Error occurred when validating your inputs',
  enrichmentFailedToastMessage:
    'We are unable to validating your inputs due to an unknown error. Your changes have been discarded in the form. Please try again or contact us if the problem persists.',
  Failed: 'Failed',
  'fieldId.StartTime': 'Auction start time',
  'fieldId.EndTime': 'Auction end time',
  'fieldId.DefaultDealers': 'Dealers',
  'fieldId.DeliveryType': 'Delivery type',
  'fieldId.DerogationReason': 'Derogation reason',
  'fieldId.DeterminationMethod': 'Determination method',
  'fieldId.FileCode': 'File code',
  'fieldId.Legs': 'Leg',
  'fieldId.LotSize': 'Lot size',
  'fieldId.MaturityDate': 'Maturity',
  'fieldId.NegotiationType': 'Nego type',
  'fieldId.NumberOfLots': 'Size (of lots)',
  'fieldId.OptionStyle': 'Option style',
  'fieldId.PositionConfirmTime': 'Position confirm time',
  'fieldId.PreNotificationTime': 'T-1 notification time',
  'fieldId.PriceUnitType': 'Price unit',
  'fieldId.Quantity': 'Indicative quantity',
  'fieldId.Notional': 'Indicative notional',
  'fieldId.SgWay': 'SG way',
  'fieldId.Strategy': 'Strategy',
  'fieldId.Strike': 'Strike',
  'fieldId.Underlying': 'Underlying',
  'fieldId.Sales': 'Sales',
  'Global information': 'Global information',
  'Go back': 'Go back',
  History: 'History',
  Iddef: 'Iddef',
  'In progress': 'In progress',
  'Indicative quantity': 'Indicative quantity',
  Instrument: 'Instrument',
  'Instrument type': 'Instrument type',
  'instrumentStyle.AMERICAN': 'American',
  'instrumentStyle.EUROPEAN': 'European',
  'instrumentType.CALL': 'Call',
  'instrumentType.CALL_SPREAD': 'Call spread',
  'instrumentType.PUT': 'Put',
  'instrumentType.PUT_SPREAD': 'Put spread',
  'instrumentType.TOTAL_RETURN_FUTURE': 'Total return future',
  'Light theme': 'Light theme',
  'Lot size': 'Lot size',
  Maturity: 'Maturity',
  'modal.override.fieldId.dealer': 'Please select the dealer you would like to override',
  'missed.confirmation': 'Would you like to proceed and notify the dealer has missed the trade?',
  'Nego type': 'Nego type',
  'negoType.LISTED': 'Listed',
  'negoType.OTC': 'OTC',
  'New auction': 'New auction',
  'New auction details': 'New auction details',
  Next: 'Next',
  No: 'No',
  'No auction found': 'No auction found',
  'No result found': 'No result found',
  OverrideQuoteSuccess: 'The quote has been successfully updated.',
  'Option style': 'Option style',
  'Override failed': 'Override failed',
  'Page not found': 'Page not found',
  'PDC status': 'PDC status',
  'PDC T-1 status': 'PDC T-1 status',
  'pdcStatus.COMPUTING': 'Pending',
  'pdcStatus.DEROGATED': 'Derogated',
  'pdcStatus.ERROR': 'Error',
  'pdcStatus.KO': 'KO',
  'pdcStatus.NEW': 'Pending',
  'pdcStatus.OK': 'OK',
  'pdcStatus.UNDEFINED': 'Pending',
  'pdcStatus.WARNING': 'Warning',
  'Please save your selection to confirm.': 'Please save your selection to confirm.',
  'please check your URL and try again.': 'please check your URL and try again.',
  'please contact us if you believe this is a mistake.': 'please contact us if you believe this is a mistake.',
  'Please contact us if you require any further assistance.':
    'Please contact us if you require any further assistance.',
  'please try again or contact us if the problem persists.': 'please try again or contact us if the issue persists.',
  'Please try again or contact us with the request ID {correlationId} if the issue persists.':
    'Please try again or contact us with the request ID {correlationId} if the issue persists.',
  'Pre deal checks T-1 status': 'Pre-deal checks T-1 status',
  'Pre-deal checks status': 'Pre-deal checks status',
  Price: 'Price',
  'Price unit': 'Price unit',
  Quantity: 'Quantity',
  'Quote updated': 'Quote updated',
  Ratio: 'Ratio',
  'Reselect dealers': 'Reselect dealers',
  'Retrieve PDC derogation reasons failed': 'Retrieve PDC derogation reasons failed',
  'Retrieve PDC details failed': 'Retrieve PDC details failed',
  'rfqStatus.Pending': 'Pending',
  Sales: 'Sales',
  'Save details': 'Save details',
  'Save selection': 'Save selection',
  'Search and select': 'Search and select',
  'search with the filter above.': 'search with the filter above.',
  'See full PDC status': 'See full PDC status',
  Select: 'Select',
  'Selection saved': 'Selection saved',
  'SG Markets Bidding': 'SG Markets Bidding',
  'SG way': 'SG way',
  'sgWay.BUY': 'SG buys',
  'sgWay.SELL': 'SG sells',
  'sgWay.TWO_WAYS': 'Two ways',
  'Size (of lots)': 'Size (of lots)',
  'Size of lot': 'Size of lot',
  Status: 'Status',
  Strike: 'Strike',
  'Strike(s)': 'Strike(s)',
  Success: 'Success',
  'T-1 notification time': 'T-1 notification time',
  'The page that you are looking for cannot be found,': 'The page that you are looking for cannot be found,',
  'This service is only available from our internal corporate network. Please connect through our corporate Wi-Fi network or VPN.':
    'This service is only available from our internal corporate network. Please connect through our corporate Wi-Fi network or VPN.',
  Time: 'Time',
  Timezone: 'Time zone',
  'To find your auctions,': 'To find your auctions,',
  'Total return future': 'Total return future',
  'toast.copyToClipboard.header': 'Copy to clipboard',
  'Unable to create auction': 'Unable to create auction',
  'Unable to retrieve auctions': 'Unable to retrieve auctions',
  'Unable to save auction details': 'Unable to save auction details',
  Underlying: 'Underlying',
  'Unexpected error': 'Unexpected error',
  'Validating your inputs': 'Validating your inputs',
  'validationError.dateBeforeAuctionStartDate': 'must be on or before Auction start date',
  'validationError.undefined': 'is required',
  'validationError.fieldIsNegativeOrZero': 'cannot be negative or zero',
  'validationError.Auction Start time has to be in the future': 'Auction start time has to be in the future',
  'validationError.Auction start time must be before Auction end time':
    'Auction start time must be before Auction end time',
  'validationError.T-1 PDC time must be before Position confirm Time':
    'T-1 PDC time must be before Position confirm Time',
  'validationError.Position confirm time must be before Auction start time':
    'Position confirm time must be before Auction start time',
  'workflowStatus.NEW': 'New auction',
  'workflowStatus.AUCTION_CREATED': 'New auction',
  'workflowStatus.AUCTION_PDC_T_MINUS_1_IN_PROGRESS': 'PDC T-1 ongoing',
  'workflowStatus.AUCTION_PDC_T_MINUS_1_COMPLETED': 'PDC T-1 completed',
  'workflowStatus.AUCTION_PDC_IN_PROGRESS': 'PDC ongoing',
  'workflowStatus.AUCTION_PDC_COMPLETED': 'Select dealer(s) for bidding',
  'workflowStatus.AUCTION_AWAITING_TO_START': 'Awaiting auction to start',
  'workflowStatus.AUCTION_ONGOING': 'Auction ongoing',
  'workflowStatus.AUCTION_CUTOFF_PASSED': 'Auction cut-off passed',
  'workflowStatus.AUCTION_WINNER_SELECTED': 'Auction ended',
  'workflowStatus.AUCTION_WINNER_SELECTED.DealerExecutionConfirmationEnabled': 'Awaiting dealer confirmation',
  'workflowStatus.AUCTION_CANCELLED': 'Auction cancelled',
  'workflowStatus.AUCTION_BOOKING_TO_BE_COMPLETED': 'Auction ended',
  'We have received some updates for the auction {strategy} and refreshed the content in your screen.':
    'We have received some updates for the auction {strategy} and refreshed the content in your screen.',
  Yes: 'Yes',
  'Yes, cancel auction': 'Yes, cancel auction',
  'You are forbidden to access to the page requested,': 'You are forbidden to access to the page requested,',
  'You are not allowed to create any new auction': 'You are not allowed to create any new auction',
  'You are not allowed to edit the auction': 'You are not allowed to edit the auction',
  'Your auction has been successfully created.': 'Your auction has been successfully created.',
  'Your auction has been successfully cancelled.': 'Your auction has been successfully cancelled.',
  'Your auction has been successfully updated.': 'Your auction has been successfully updated.',
  'Your auction winner has been successfully selected.': 'Your auction winner has been successfully selected.',
  'Your dealer selection has been successfully saved.': 'Your dealer selection has been successfully saved.',
  'Please enter all time in {timeZoneId} time zone.': 'Please enter all time in {timeZoneId} time zone.',
};
