import { FormattedMessage } from 'react-intl';
import { isDefined } from '@sgme/fp';
import type { Auction, RfqDealers } from '@/models';
import type { FC } from 'react';

export interface ExecutionStatusBadgeProps {
  auction?: Auction;
}

export const ExecutionStatusBadge: FC<ExecutionStatusBadgeProps> = ({ auction }) => {
  const showPendingBadge = isExecutionPendingOrMissed(auction?.rfqDealers);
  const showConfirmedBadge = isExecutionConfirmed(auction?.rfqDealers);
  return (
    <>
      {showPendingBadge && !showConfirmedBadge && (
        <span className={`d-inline-flex align-items-center badge badge-discreet-info rounded-pill ms-2`}>
          <FormattedMessage id={`executionStatus.pending`} />
        </span>
      )}
      {showConfirmedBadge && (
        <span className={`d-inline-flex align-items-center badge badge-discreet-success rounded-pill ms-2`}>
          <FormattedMessage id={`executionStatus.confirmed`} />
        </span>
      )}
    </>
  );
};

export function isExecutionPendingOrMissed(rfqDealers: RfqDealers[] | undefined): boolean {
  if (rfqDealers === undefined) {
    return false;
  }

  return isExecutionPending(rfqDealers) || isExecutionMissed(rfqDealers);
}

export function isAnyDealerSelected(rfqDealers: RfqDealers[] | undefined): boolean {
  if (rfqDealers === undefined) {
    return false;
  }
  return (
    rfqDealers.find(
      dealer => dealer.dealerConfirmationStatus !== 'NOT_SELECTED' && dealer.dealerConfirmationStatus !== undefined,
    ) !== undefined
  );
}

export function isExecutionConfirmed(rfqDealers: RfqDealers[] | undefined): boolean {
  if (rfqDealers === undefined) {
    return false;
  }
  return isDefined(rfqDealers.find(dealer => dealer.dealerConfirmationStatus === 'ACKNOWLEDGED'));
}

export function isExecutionPending(rfqDealers: RfqDealers[] | undefined): boolean {
  if (rfqDealers === undefined) {
    return false;
  }
  return isDefined(rfqDealers.find(dealer => dealer.dealerConfirmationStatus === 'PENDING'));
}

export function isExecutionMissed(rfqDealers: RfqDealers[] | undefined): boolean {
  if (rfqDealers === undefined) {
    return false;
  }
  return isDefined(rfqDealers.find(dealer => dealer.dealerConfirmationStatus === 'MISSED'));
}

export function isDealerAcknowledged(rfqDealer: RfqDealers | undefined): boolean {
  if (rfqDealer === undefined) {
    return false;
  }
  return rfqDealer.dealerConfirmationStatus === 'ACKNOWLEDGED';
}

export function isDealerMissed(rfqDealer: RfqDealers | undefined): boolean {
  if (rfqDealer === undefined) {
    return false;
  }
  return rfqDealer.dealerConfirmationStatus === 'MISSED';
}

export function isDealerPending(rfqDealer: RfqDealers | undefined): boolean {
  if (rfqDealer === undefined) {
    return false;
  }
  return rfqDealer.dealerConfirmationStatus === 'PENDING';
}

export function isDealerMissedOrPending(rfqDealer: RfqDealers | undefined): boolean {
  if (rfqDealer === undefined) {
    return false;
  }
  return isDealerMissed(rfqDealer) || isDealerPending(rfqDealer);
}

export function isDealerNotSelected(rfqDealer: RfqDealers | undefined): boolean {
  if (rfqDealer === undefined) {
    return false;
  }
  return !isDealerAcknowledged(rfqDealer) && !isDealerMissedOrPending(rfqDealer);
}
