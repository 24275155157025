import { Collapse } from 'reactstrap';
import { useFoldableCardContext } from '@/components/shared/FoldableCard/FoldableCardContext';
import { useToggleFeature } from '@/hooks/useToggleFeature/useToggleFeature';
import classNames from 'classnames';
import type { FC, PropsWithChildren } from 'react';

export const FoldableCardBody: FC<PropsWithChildren> = ({ children }) => {
  const { open } = useFoldableCardContext();
  const isDealerExecutionConfirmationEnabled = useToggleFeature('DealerExecutionConfirmation');
  return (
    <Collapse
      tag="div"
      className={classNames('card card-body border-top p-3', isDealerExecutionConfirmationEnabled ? '' : 'bg-lvl2')}
      isOpen={open}
    >
      {children}
    </Collapse>
  );
};
