import { AlertBanner } from '@/components/shared/AlertBanner';
import { Button } from '@sgme/ui';
import { type FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { openAcknowledgeModal, openMissedModal } from '@/store/ui';
import { useDispatch } from 'react-redux';

export interface PendingBannerProps {
  auctionUuid?: string;
}

export const PendingBanner: FC<PendingBannerProps> = ({ auctionUuid }) => {
  const dispatch = useDispatch();

  const onOpenAcknowledgeModal = useCallback(() => {
    dispatch(openAcknowledgeModal(auctionUuid));
  }, [dispatch, auctionUuid]);

  const onOpenMissedModal = useCallback(() => {
    dispatch(openMissedModal(auctionUuid));
  }, [dispatch, auctionUuid]);

  return (
    <AlertBanner color="info" title="confirmDealerExecution" iconName="info_outline" className="mb-2">
      <div className="ms-auto d-flex">
        <Button
          purpose="discreet"
          color="primary-alt"
          size="md"
          className="btn-icon-start"
          onClick={onOpenMissedModal}
          data-e2e={`${auctionUuid}-miss-button`}
        >
          <i className="icon icon-sm">close</i>
          <FormattedMessage id={`executionPendingBanner.missed`} />
        </Button>
        <Button
          purpose="primary"
          color="primary-alt"
          size="md"
          className="btn-icon-start text-info ms-2"
          onClick={onOpenAcknowledgeModal}
          data-e2e={`${auctionUuid}-confirm-button`}
        >
          <i className="icon icon-sm">check</i>
          <FormattedMessage id={`executionPendingBanner.confirmed`} />
        </Button>
      </div>
    </AlertBanner>
  );
};
