import type {
  Auction,
  BlotterItem,
  ConfirmDealersRequest,
  ConfirmDealersResponse,
  CreateAuctionResponse,
  PdcDerogation,
  PdcDetails,
  StrategyFormResult,
  StrategyTypeEnum,
  VariationParameters,
} from '@/models';
import type { AuctionExecutionBookingDetails, ExecutionConfirmationStep } from '@/models/AuctionExecutionState';
import type { ErrorResponse } from '@/models/Responses/ErrorResponse';

export type AuctionsActions =
  | CurrentAuctionsRequested
  | CurrentAuctionsReceived
  | CurrentAuctionsFailed
  | CreateAuctionRequested
  | CreateAuctionReceived
  | CreateAuctionFailed
  | CreateAuctionQueued
  | EditAuctionRequested
  | EditAuctionReceived
  | EditAuctionFailed
  | EditAuctionQueued
  | ReloadAuctionRequested
  | ReloadAuctionReceived
  | ReloadAuctionFailed
  | InitStrategyFormRequested
  | InitStrategyFormReceived
  | InitStrategyFormFailed
  | EnrichStrategyFormRequested
  | EnrichStrategyFormReceived
  | EnrichStrategyFormFailed
  | EnrichStrategyFormCleared
  | AuctionDealersRequested
  | AuctionDealersReceived
  | AuctionDealersFailed
  | LatestBlotterItemsReceived
  | LeaveCurrentAuctionPage
  | ExecuteAuctionsDealerSelected
  | ExecuteAuctionsConfirmationInitiated
  | ExecuteAuctionsConfirmationCancelled
  | ExecuteAuctionsConfirmationStepChanged
  | ExecuteAuctionsBookingDetailsPatchRequested
  | ExecuteAuctionsRequested
  | ExecuteAuctionsReceived
  | ExecuteAuctionsFailed
  | OverrideQuoteRequested
  | OverrideQuoteReceived
  | OverrideQuoteFailed
  | CancelAuctionRequested
  | CancelAuctionReceived
  | CancelAuctionFailed
  | PdcDetailsRequested
  | PdcDetailsReceived
  | PdcDetailsFailed
  | DerogationReasonsRequested
  | DerogationReasonsReceived
  | DerogationReasonsFailed
  | DerogateRequested
  | DerogateReceived
  | DerogateFailed
  | AcknowledgeAuctionRequested
  | AcknowledgeAuctionReceived
  | AcknowledgeAuctionFailed
  | MissedAuctionRequested
  | MissedAuctionReceived
  | MissedAuctionFailed;

export const CURRENT_AUCTIONS_REQUESTED = '[Auctions API] Current auctions requested' as const;
export const currentAuctionsRequested = () => ({
  type: CURRENT_AUCTIONS_REQUESTED,
});
export type CurrentAuctionsRequested = ReturnType<typeof currentAuctionsRequested>;

export const CURRENT_AUCTIONS_RECEIVED = '[Auctions API] Current auctions received' as const;
export const currentAuctionsReceived = (blotterItems: BlotterItem[]) => ({
  type: CURRENT_AUCTIONS_RECEIVED,
  blotterItems,
});
export type CurrentAuctionsReceived = ReturnType<typeof currentAuctionsReceived>;

export const CURRENT_AUCTIONS_FAILED = '[Auctions API] Current auctions failed' as const;
export const currentAuctionsFailed = (error: ErrorResponse) => ({
  type: CURRENT_AUCTIONS_FAILED,
  error,
});
export type CurrentAuctionsFailed = ReturnType<typeof currentAuctionsFailed>;

export const CREATE_AUCTION_REQUESTED = '[Auctions API] Create auction requested' as const;
export const createAuctionRequested = () => ({
  type: CREATE_AUCTION_REQUESTED,
});
export type CreateAuctionRequested = ReturnType<typeof createAuctionRequested>;

export const CREATE_AUCTION_RECEIVED = '[Auctions API] Create auction received' as const;
export const createAuctionReceived = (response: CreateAuctionResponse) => ({
  type: CREATE_AUCTION_RECEIVED,
  response,
});
export type CreateAuctionReceived = ReturnType<typeof createAuctionReceived>;

export const CREATE_AUCTION_FAILED = '[Auctions API] Create auction failed' as const;
export const createAuctionFailed = (error: ErrorResponse) => ({
  type: CREATE_AUCTION_FAILED,
  error,
});
export type CreateAuctionFailed = ReturnType<typeof createAuctionFailed>;

export const CREATE_AUCTION_QUEUED = '[Auctions API] Create auction queued' as const;
export const createAuctionQueued = () => ({
  type: CREATE_AUCTION_QUEUED,
});
export type CreateAuctionQueued = ReturnType<typeof createAuctionQueued>;

export const ENRICH_STRATEGY_FORM_REQUESTED = '[Auctions API] Enrich strategy form requested' as const;
export const enrichStrategyFormRequested = (variationParameters: VariationParameters) => ({
  type: ENRICH_STRATEGY_FORM_REQUESTED,
  variationParameters,
});
export type EnrichStrategyFormRequested = ReturnType<typeof enrichStrategyFormRequested>;

export const ENRICH_STRATEGY_FORM_RECEIVED = '[Auctions API] Enrich strategy form received' as const;
export const enrichStrategyFormReceived = (strategyFormResult: StrategyFormResult) => ({
  type: ENRICH_STRATEGY_FORM_RECEIVED,
  strategyFormResult,
});
export type EnrichStrategyFormReceived = ReturnType<typeof enrichStrategyFormReceived>;

export const ENRICH_STRATEGY_FORM_FAILED = '[Auctions API] Enrich strategy form failed' as const;
export const enrichStrategyFormFailed = (error: ErrorResponse) => ({
  type: ENRICH_STRATEGY_FORM_FAILED,
  error,
});
export type EnrichStrategyFormFailed = ReturnType<typeof enrichStrategyFormFailed>;

export const ENRICH_STRATEGY_FORM_CLEARED = '[Auctions API] Enrich strategy form cleared' as const;
export const enrichStrategyFormCleared = () => ({
  type: ENRICH_STRATEGY_FORM_CLEARED,
});
export type EnrichStrategyFormCleared = ReturnType<typeof enrichStrategyFormCleared>;

export const RELOAD_AUCTION_REQUESTED = '[Auctions API] Reload auction requested' as const;
export const reloadAuctionRequested = (auctionUuid: string) => ({
  type: RELOAD_AUCTION_REQUESTED,
  auctionUuid,
});
export type ReloadAuctionRequested = ReturnType<typeof reloadAuctionRequested>;

export const RELOAD_AUCTION_RECEIVED = '[Auctions API] Reload auction received' as const;
export const reloadAuctionReceived = (strategyFormResult: StrategyFormResult) => ({
  type: RELOAD_AUCTION_RECEIVED,
  strategyFormResult,
});
export type ReloadAuctionReceived = ReturnType<typeof reloadAuctionReceived>;

export const RELOAD_AUCTION_FAILED = '[Auctions API] Reload auction failed' as const;
export const reloadAuctionFailed = (error: ErrorResponse) => ({
  type: RELOAD_AUCTION_FAILED,
  error,
});
export type ReloadAuctionFailed = ReturnType<typeof reloadAuctionFailed>;

export const EDIT_AUCTION_REQUESTED = '[Auctions API] Edit auction request' as const;
export const editAuctionRequested = (auctionUuid: string) => ({
  type: EDIT_AUCTION_REQUESTED,
  auctionUuid,
});
export type EditAuctionRequested = ReturnType<typeof editAuctionRequested>;

export const EDIT_AUCTION_RECEIVED = '[Auctions API] Edit auction received' as const;
export const editAuctionReceived = (strategyFormResult: StrategyFormResult) => ({
  type: EDIT_AUCTION_RECEIVED,
  strategyFormResult,
});
export type EditAuctionReceived = ReturnType<typeof editAuctionReceived>;

export const EDIT_AUCTION_FAILED = '[Auctions API] Edit auction failed' as const;
export const editAuctionFailed = (error: ErrorResponse) => ({
  type: EDIT_AUCTION_FAILED,
  error,
});
export type EditAuctionFailed = ReturnType<typeof editAuctionFailed>;

export const EDIT_AUCTION_QUEUED = '[Auctions API] Edit auction queued' as const;
export const editAuctionQueued = (auctionUuid?: string) => ({
  type: EDIT_AUCTION_QUEUED,
  auctionUuid,
});
export type EditAuctionQueued = ReturnType<typeof editAuctionQueued>;

export const INIT_STRATEGY_FORM_REQUESTED = '[Auctions API] Init strategy form requested' as const;
export const initStrategyFormRequested = (page: 'create' | 'edit', strategyType: StrategyTypeEnum) => ({
  type: INIT_STRATEGY_FORM_REQUESTED,
  page,
  strategyType,
});
export type InitStrategyFormRequested = ReturnType<typeof initStrategyFormRequested>;

export const INIT_STRATEGY_FORM_RECEIVED = '[Auctions API] Init strategy form received' as const;
export const initStrategyFormReceived = (strategyFormResult: StrategyFormResult) => ({
  type: INIT_STRATEGY_FORM_RECEIVED,
  strategyFormResult,
});
export type InitStrategyFormReceived = ReturnType<typeof initStrategyFormReceived>;

export const INIT_STRATEGY_FORM_FAILED = '[Auctions API] Init strategy form failed' as const;
export const initStrategyFormFailed = (error: ErrorResponse) => ({
  type: INIT_STRATEGY_FORM_FAILED,
  error,
});
export type InitStrategyFormFailed = ReturnType<typeof initStrategyFormFailed>;

export const AUCTION_DEALERS_REQUESTED = '[Auctions API] Auction dealers requested' as const;
export const auctionDealersRequested = (auctionUuid: string, request: ConfirmDealersRequest) => ({
  type: AUCTION_DEALERS_REQUESTED,
  auctionUuid,
  request,
});
export type AuctionDealersRequested = ReturnType<typeof auctionDealersRequested>;

export const AUCTION_DEALERS_RECEIVED = '[Auctions API] Auction dealers received' as const;
export const auctionDealersReceived = (auctionUuid: string, payload: ConfirmDealersResponse) => ({
  type: AUCTION_DEALERS_RECEIVED,
  auctionUuid,
  payload,
});
export type AuctionDealersReceived = ReturnType<typeof auctionDealersReceived>;

export const AUCTION_DEALERS_FAILED = '[Auctions API] Auction dealers failed' as const;
export const auctionDealersFailed = (error: ErrorResponse) => ({
  type: AUCTION_DEALERS_FAILED,
  error,
});
export type AuctionDealersFailed = ReturnType<typeof auctionDealersFailed>;

export const LATEST_BLOTTER_ITEMS_RECEIVED = '[Auctions API] Latest blotter items received' as const;
export const latestBlotterItemsReceived = (blotterItems: BlotterItem[]) => ({
  type: LATEST_BLOTTER_ITEMS_RECEIVED,
  blotterItems,
});
export type LatestBlotterItemsReceived = ReturnType<typeof latestBlotterItemsReceived>;

export const LEAVE_CURRENT_AUCTION_PAGE = '[Auctions API] Leave current auction page' as const;
export const leaveCurrentAuctionPage = () => ({
  type: LEAVE_CURRENT_AUCTION_PAGE,
});
export type LeaveCurrentAuctionPage = ReturnType<typeof leaveCurrentAuctionPage>;

export const EXECUTE_AUCTIONS_DEALER_SELECTED = '[Auctions API] Execute auctions dealer selected' as const;
export const executeAuctionsDealerSelected = (auctionId: string, dealerId: string) => ({
  type: EXECUTE_AUCTIONS_DEALER_SELECTED,
  auctionId,
  dealerId,
});
export type ExecuteAuctionsDealerSelected = ReturnType<typeof executeAuctionsDealerSelected>;

export const EXECUTE_AUCTIONS_CONFIRMATION_INITIATED =
  '[Auctions API] Execute auctions confirmation initiated' as const;
export const executeAuctionsConfirmationInitiated = (auctionId: string, dealerId: string, auction?: Auction) => ({
  type: EXECUTE_AUCTIONS_CONFIRMATION_INITIATED,
  auctionId,
  dealerId,
  auction,
});
export type ExecuteAuctionsConfirmationInitiated = ReturnType<typeof executeAuctionsConfirmationInitiated>;

export const EXECUTE_AUCTIONS_CONFIRMATION_CANCELLED =
  '[Auctions API] Execute auctions confirmation cancelled' as const;
export const executeAuctionsConfirmationCancelled = () => ({
  type: EXECUTE_AUCTIONS_CONFIRMATION_CANCELLED,
});
export type ExecuteAuctionsConfirmationCancelled = ReturnType<typeof executeAuctionsConfirmationCancelled>;

export const EXECUTE_AUCTIONS_CONFIRMATION_STEP_CHANGED =
  '[Auctions API] Execute auctions confirmation step changed' as const;
export const executeAuctionsConfirmationStepChanged = (step: ExecutionConfirmationStep) => ({
  type: EXECUTE_AUCTIONS_CONFIRMATION_STEP_CHANGED,
  step,
});
export type ExecuteAuctionsConfirmationStepChanged = ReturnType<typeof executeAuctionsConfirmationStepChanged>;

export const EXECUTE_AUCTIONS_BOOKING_DETAILS_PATCH_REQUESTED =
  '[Auctions API] Execute auctions booking details patch requested' as const;
export const executeAuctionsBookingDetailsPatchRequested = (bookingDetails: AuctionExecutionBookingDetails) => ({
  type: EXECUTE_AUCTIONS_BOOKING_DETAILS_PATCH_REQUESTED,
  bookingDetails,
});
export type ExecuteAuctionsBookingDetailsPatchRequested = ReturnType<
  typeof executeAuctionsBookingDetailsPatchRequested
>;

export const EXECUTE_AUCTIONS_REQUESTED = '[Auctions API] Execute auctions requested' as const;
export const executeAuctionsRequested = (auctionId: string, dealerId: string) => ({
  type: EXECUTE_AUCTIONS_REQUESTED,
  auctionId,
  dealerId,
});
export type ExecuteAuctionsRequested = ReturnType<typeof executeAuctionsRequested>;

export const EXECUTE_AUCTIONS_RECEIVED = '[Auctions API] Execute auctions received' as const;
export const executeAuctionsReceived = () => ({
  type: EXECUTE_AUCTIONS_RECEIVED,
});
export type ExecuteAuctionsReceived = ReturnType<typeof executeAuctionsReceived>;

export const EXECUTE_AUCTIONS_FAILED = '[Auctions API] Execute auctions failed' as const;
export const executeAuctionsFailed = (error: ErrorResponse) => ({
  type: EXECUTE_AUCTIONS_FAILED,
  error,
});
export type ExecuteAuctionsFailed = ReturnType<typeof executeAuctionsFailed>;

export const OVERRIDE_QUOTE_REQUESTED = '[Auctions API] Override quote requested' as const;
export const overrideQuoteRequested = (
  auctionUuid: string,
  dealerRfqId?: string,
  priceValue?: number,
  priceUnit?: string,
  priceType?: string,
  receiveTime?: string,
) => ({
  type: OVERRIDE_QUOTE_REQUESTED,
  auctionUuid,
  dealerRfqId,
  priceValue,
  priceUnit,
  priceType,
  receiveTime,
});
export type OverrideQuoteRequested = ReturnType<typeof overrideQuoteRequested>;

export const OVERRIDE_QUOTE_RECEIVED = '[Auctions API] Override quote received' as const;
export const overrideQuoteReceived = (
  auctionUuid: string,
  dealerRfqId: string | undefined,
  priceValue: number | undefined,
  priceUnit: string | null | undefined,
  priceType: string | null | undefined,
  receiveTime: string,
) => ({
  type: OVERRIDE_QUOTE_RECEIVED,
  auctionUuid,
  dealerRfqId,
  priceValue,
  priceUnit,
  priceType,
  receiveTime,
});
export type OverrideQuoteReceived = ReturnType<typeof overrideQuoteReceived>;

export const OVERRIDE_QUOTE_FAILED = '[Auctions API] Override quote failed' as const;
export const overrideQuoteFailed = (error: ErrorResponse) => ({
  type: OVERRIDE_QUOTE_FAILED,
  error: error,
});
export type OverrideQuoteFailed = ReturnType<typeof overrideQuoteFailed>;

export const CANCEL_AUCTION_REQUESTED = '[Auctions API] Cancel auction requested' as const;
export const cancelAuctionRequested = (auctionUuid: string) => ({
  type: CANCEL_AUCTION_REQUESTED,
  auctionUuid,
});
export type CancelAuctionRequested = ReturnType<typeof cancelAuctionRequested>;

export const CANCEL_AUCTION_RECEIVED = '[Auctions API] Cancel auction received' as const;
export const cancelAuctionReceived = (auctionUuid: string) => ({
  type: CANCEL_AUCTION_RECEIVED,
  auctionUuid,
});
export type CancelAuctionReceived = ReturnType<typeof cancelAuctionReceived>;

export const CANCEL_AUCTION_FAILED = '[Auctions API] Cancel auction failed' as const;
export const cancelAuctionFailed = (error: ErrorResponse) => ({
  type: CANCEL_AUCTION_FAILED,
  error: error,
});
export type CancelAuctionFailed = ReturnType<typeof cancelAuctionFailed>;

export const PDC_DETAILS_REQUESTED = '[Auctions API] Pdc details requested' as const;
export const pdcDetailsRequested = (rfqIds: string[] | undefined) => ({
  type: PDC_DETAILS_REQUESTED,
  rfqIds: rfqIds,
});
export type PdcDetailsRequested = ReturnType<typeof pdcDetailsRequested>;

export const PDC_DETAILS_RECEIVED = '[Auctions API] Pdc details received' as const;
export const pdcDetailsReceived = (pdcDetails: PdcDetails[]) => ({
  type: PDC_DETAILS_RECEIVED,
  pdcDetails: pdcDetails,
});
export type PdcDetailsReceived = ReturnType<typeof pdcDetailsReceived>;

export const PDC_DETAILS_FAILED = '[Auctions API] Pdc details failed' as const;
export const pdcDetailsFailed = (error: ErrorResponse) => ({
  type: PDC_DETAILS_FAILED,
  error: error,
});
export type PdcDetailsFailed = ReturnType<typeof pdcDetailsFailed>;

export const DEROGATION_REASONS_REQUESTED = '[Auctions API] derogation reasons requested' as const;
export const derogationReasonsRequested = () => ({
  type: DEROGATION_REASONS_REQUESTED,
});
export type DerogationReasonsRequested = ReturnType<typeof derogationReasonsRequested>;

export const DEROGATION_REASONS_RECEIVED = '[Auctions API] Derogation reasons received' as const;
export const derogationReasonsReceived = (pdcDerogation: PdcDerogation) => ({
  type: DEROGATION_REASONS_RECEIVED,
  pdcDerogation: pdcDerogation,
});
export type DerogationReasonsReceived = ReturnType<typeof derogationReasonsReceived>;

export const DEROGATION_REASONS_FAILED = '[Auctions API] Derogation reasons failed' as const;
export const derogationReasonsFailed = (error: ErrorResponse) => ({
  type: DEROGATION_REASONS_FAILED,
  error: error,
});
export type DerogationReasonsFailed = ReturnType<typeof derogationReasonsFailed>;

export const DEROGATE_REQUESTED = '[Auctions API] derogate requested' as const;
export const derogateRequested = (
  derogationReason: string | undefined,
  pdcUuid: string | undefined,
  rfqId: string | undefined,
) => ({
  type: DEROGATE_REQUESTED,
  derogationReason: derogationReason,
  pdcUuid: pdcUuid,
  rfqId: rfqId,
});
export type DerogateRequested = ReturnType<typeof derogateRequested>;

export const DEROGATE_RECEIVED = '[Auctions API] Derogate received' as const;
export const derogateReceived = () => ({
  type: DEROGATE_RECEIVED,
});
export type DerogateReceived = ReturnType<typeof derogateReceived>;

export const DEROGATE_FAILED = '[Auctions API] Derogate failed' as const;
export const derogateFailed = (error: ErrorResponse) => ({
  type: DEROGATE_FAILED,
  error: error,
});
export type DerogateFailed = ReturnType<typeof derogateFailed>;

export const ACKNOWLEDGE_AUCTION_REQUESTED = '[Auctions API] Acknowledge auction requested' as const;
export const acknowledgeAuctionRequested = (auctionUuid?: string) => ({
  type: ACKNOWLEDGE_AUCTION_REQUESTED,
  auctionUuid,
});
export type AcknowledgeAuctionRequested = ReturnType<typeof acknowledgeAuctionRequested>;

export const ACKNOWLEDGE_AUCTION_RECEIVED = '[Auctions API] Acknowledge auction received' as const;
export const acknowledgeAuctionReceived = (auctionUuid: string) => ({
  type: ACKNOWLEDGE_AUCTION_RECEIVED,
  auctionUuid,
});
export type AcknowledgeAuctionReceived = ReturnType<typeof acknowledgeAuctionReceived>;

export const ACKNOWLEDGE_AUCTION_FAILED = '[Auctions API] Acknowledge auction failed' as const;
export const acknowledgeAuctionFailed = (error: ErrorResponse) => ({
  type: ACKNOWLEDGE_AUCTION_FAILED,
  error,
});
export type AcknowledgeAuctionFailed = ReturnType<typeof acknowledgeAuctionFailed>;

export const MISSED_AUCTION_REQUESTED = '[Auctions API] Missed auction requested' as const;
export const missedAuctionRequested = (auctionUuid?: string) => ({
  type: MISSED_AUCTION_REQUESTED,
  auctionUuid,
});
export type MissedAuctionRequested = ReturnType<typeof missedAuctionRequested>;

export const MISSED_AUCTION_RECEIVED = '[Auctions API] Missed auction received' as const;
export const missedAuctionReceived = (auctionUuid: string) => ({
  type: MISSED_AUCTION_RECEIVED,
  auctionUuid,
});
export type MissedAuctionReceived = ReturnType<typeof missedAuctionReceived>;

export const MISSED_AUCTION_FAILED = '[Auctions API] Missed auction failed' as const;
export const missedAuctionFailed = (error: ErrorResponse) => ({
  type: MISSED_AUCTION_FAILED,
  error,
});
export type MissedAuctionFailed = ReturnType<typeof missedAuctionFailed>;
