import { Card, CardBody, CardHeader, CardTitle } from '@/components/shared/Card';
import {
  ExecutionStatusBadge,
  isDealerNotSelected,
  isDealerPending,
  isExecutionPending,
} from '@/components/ExecutionDetails/ExecutionStatusBadge';
import { type FC, useMemo } from 'react';
import { FormattedNumber } from '@/components/shared/FormattedNumber';
import { FormattedText } from '@/components/shared/FormattedText';
import { PendingBanner } from '@/components/ExecutionDetails/PendingBanner';
import { Table, Tbody, Td, Th, Thead } from '@/components/shared/Table';
import { formatNumberWithDecimals, toTitleCase } from '@/utils/string';
import { getExecutionStatusTextColor } from '@/utils/status/status-colors.utils';
import { hasPermission } from '@/store/users';
import { isDefined } from '@sgme/fp';
import { useSelector } from 'react-redux';
import { useToggleFeature } from '@/hooks/useToggleFeature/useToggleFeature';
import classNames from 'classnames';
import styled from 'styled-components';
import type { Auction } from '@/models';
import type { IntlKey } from '@/locales';

export interface ExecutionDetailsProps {
  auction?: Auction;
  className?: string;
}

const StyledHeaderRow = styled.tr<{ hasPendingDealerConfirmation: boolean }>`
  > th {
    border-bottom-color: ${props => (props.hasPendingDealerConfirmation ? '#2469ff' : '#000')} !important;
  }
`;

export const ExecutionDetails: FC<ExecutionDetailsProps> = ({ auction, className }) => {
  const isDealerExecutionConfirmationEnabled = useToggleFeature('DealerExecutionConfirmation');
  const hasSelectAuctionWinnerPermission = useSelector(hasPermission('SELECT_AUCTION_WINNER'));

  const winningDealers = useMemo(() => {
    if (!isDealerExecutionConfirmationEnabled) {
      return auction?.rfqDealers;
    }
    return auction?.rfqDealers?.filter(dealer => dealer.dealerConfirmationStatus !== 'NOT_SELECTED');
  }, [isDealerExecutionConfirmationEnabled, auction?.rfqDealers]);

  const executionDetailsDealer = winningDealers?.find(d => d.executedQuote === true || !isDealerNotSelected(d));
  const executionDetailsInstrument = auction?.auctionBaseRfq?.instruments?.at(0);
  const executionDetailsInstrumentMasterLeg = auction?.auctionBaseRfq?.instruments
    ?.at(0)
    ?.instrumentLegs?.find(i => i.master === true);
  const executionDetailsInstrumentName = `${toTitleCase(executionDetailsInstrument?.instrumentType ?? '')} ${executionDetailsInstrumentMasterLeg?.underlying?.bloombergCode}`;

  return (
    <Card padding={3} className={className}>
      <CardHeader>
        <CardTitle size={6}>
          <FormattedText id="executionDetails.title" />
          {isDealerExecutionConfirmationEnabled && hasSelectAuctionWinnerPermission && (
            <ExecutionStatusBadge auction={auction} />
          )}
        </CardTitle>
      </CardHeader>
      <CardBody>
        {isDealerExecutionConfirmationEnabled &&
          isExecutionPending(winningDealers) &&
          hasSelectAuctionWinnerPermission && <PendingBanner auctionUuid={auction?.auctionUuid} />}
        <Table className="table-sm w-100 m-0 bg-lvl1">
          <Thead>
            <StyledHeaderRow
              hasPendingDealerConfirmation={isDealerExecutionConfirmationEnabled && isExecutionPending(winningDealers)}
            >
              <FormattedText as={Th} id="executionDetails.dealer" className="text-secondary" />
              <FormattedText as={Th} id="executionDetails.instrument" className="text-secondary" />
              <FormattedText
                as={Th}
                id="executionDetails.quantity"
                className="text-secondary"
                style={{ textAlign: 'right' }}
              />
              <FormattedText
                as={Th}
                id="executionDetails.price"
                className="text-secondary"
                style={{ textAlign: 'right' }}
              />
              <FormattedText as={Th} id="executionDetails.bookingPortfolio" className="text-secondary" />
              <FormattedText as={Th} id="executionDetails.bookingCentre" className="text-secondary" />
              <FormattedText as={Th} id="executionDetails.markitWireId" className="text-secondary" />
              {isDealerExecutionConfirmationEnabled && (
                <FormattedText as={Th} id="executionDetails.status" className="text-secondary" />
              )}
            </StyledHeaderRow>
          </Thead>
          {isDealerExecutionConfirmationEnabled ? (
            <Tbody>
              {winningDealers?.map(dealer => (
                <tr
                  key={dealer.dealerRfqId}
                  className={classNames({
                    'table-info border-info': isDealerExecutionConfirmationEnabled && isDealerPending(dealer),
                  })}
                >
                  <FormattedText
                    as={Td}
                    text={dealer?.salesCounterparty?.name}
                    className={classNames('fw-bold', {
                      'border-end': isDealerExecutionConfirmationEnabled && isDealerPending(dealer),
                    })}
                  />
                  <FormattedText
                    as={Td}
                    text={executionDetailsInstrumentName}
                    className={classNames({
                      'border-end': isDealerExecutionConfirmationEnabled && isDealerPending(dealer),
                    })}
                  />
                  <FormattedNumber
                    as={Td}
                    className={classNames('fw-semibold', {
                      'border-end': isDealerExecutionConfirmationEnabled && isDealerPending(dealer),
                    })}
                    value={executionDetailsInstrumentMasterLeg?.negotiatedSize?.quantity}
                    align="right"
                  />
                  <td
                    style={{ textAlign: 'right' }}
                    className={classNames({
                      'border-end': isDealerExecutionConfirmationEnabled && isDealerPending(dealer),
                    })}
                  >
                    <span className="fw-semibold">
                      {isDefined(dealer?.price?.value) ? formatNumberWithDecimals(dealer.price.value, 4) : ''}
                    </span>
                    <span className="ms-1 text-secondary">{dealer?.price?.unit}</span>
                  </td>
                  <FormattedText
                    as={Td}
                    text={executionDetailsInstrument?.bookingPortfolio}
                    className={classNames({
                      'border-end': isDealerExecutionConfirmationEnabled && isDealerPending(dealer),
                    })}
                  />
                  <FormattedText
                    as={Td}
                    text={executionDetailsInstrument?.bookingCenter}
                    className={classNames({
                      'border-end': isDealerExecutionConfirmationEnabled && isDealerPending(dealer),
                    })}
                  />
                  <FormattedText
                    as={Td}
                    text={auction?.markitWireId}
                    className={classNames({
                      'border-end': isDealerExecutionConfirmationEnabled && isDealerPending(dealer),
                    })}
                  />
                  <FormattedText
                    as={Td}
                    className={getExecutionStatusTextColor(dealer.dealerConfirmationStatus)}
                    id={
                      `executionDetails.status.${(dealer.dealerConfirmationStatus ?? 'PENDING').toLocaleLowerCase()}` as IntlKey
                    }
                  />
                </tr>
              ))}
            </Tbody>
          ) : (
            <Tbody>
              <tr>
                <FormattedText as={Td} text={executionDetailsDealer?.salesCounterparty?.name} className="fw-bold" />
                <FormattedText as={Td} text={executionDetailsInstrumentName} />
                <FormattedNumber
                  as={Td}
                  className="fw-semibold"
                  value={executionDetailsInstrumentMasterLeg?.negotiatedSize?.quantity}
                  align="right"
                />
                <div style={{ textAlign: 'right' }}>
                  <span className="fw-semibold">
                    {isDefined(executionDetailsDealer?.price?.value)
                      ? formatNumberWithDecimals(executionDetailsDealer.price.value, 4)
                      : ''}
                  </span>
                  <span className="ms-1 text-secondary">{executionDetailsDealer?.price?.unit}</span>
                </div>
                <FormattedText as={Td} text={executionDetailsInstrument?.bookingPortfolio} />
                <FormattedText as={Td} text={executionDetailsInstrument?.bookingCenter} />
                <FormattedText as={Td} text={auction?.markitWireId} />
              </tr>
            </Tbody>
          )}
        </Table>
      </CardBody>
    </Card>
  );
};
